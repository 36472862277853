html,
body,
#root {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  min-height: 100%;
  background-color: unset;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  background-color: #dadada;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 4px;
}

.debug {
  box-sizing: border-box;
  border: 2px solid red !important;
}

.maplibregl-ctrl-attrib-inner {
  color: black !important;
}